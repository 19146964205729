<template>
    <div>
        <el-button type="primary" :disabled="loading" @click="openDialog()">新建</el-button>
    </div>
    <el-table style="margin-top: 10px" v-loading="loading" :data="data">
        <el-table-column label="ID" width="150" prop="id"></el-table-column>
        <el-table-column label="name" width="150" prop="name"></el-table-column>
        <el-table-column label="category" width="150" prop="category"></el-table-column>
        <el-table-column label="label" width="100" prop="label">
          <template #default="scope">
            <el-tag v-if="scope.row.label">{{ scope.row.label }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="tags" width="200">
          <template #default="scope">
            <el-tag style="margin: 3px" v-if="scope.row.tags" v-for="t in scope.row.tags">{{ t }}</el-tag>
          </template>
        </el-table-column>

        <el-table-column label="图片" width="250px" prop="cover">
            <template #default="scope">
                <el-image lazy :src="`${$root.cdn_url}/${scope.row.cover}`"
                          style="width: 250px"></el-image>
            </template>
        </el-table-column>
        <el-table-column label="上线" width="100px" prop="active">
            <template #default="scope">
                <el-switch v-model="scope.row.active" :inactive-value="false" :active-value="true"
                           @change="update(scope.row)"></el-switch>
            </template>
        </el-table-column>

        <el-table-column label="操作" width="250">
            <template #default="scope">
                <el-button type="success" circle icon="Top" :loading="loading"
                           :disabled="scope.$index===0" @click="moveUp(scope.$index)"></el-button>
                <el-button type="success" circle icon="Bottom" :loading="loading"
                           :disabled="scope.$index===data.length - 1" @click="moveDown(scope.$index)"></el-button>
                <el-button size="small" :disabled="loading" @click="openDialog(scope.row)">编辑</el-button>
                <el-button size="small" type="danger" :disabled="loading" @click="remove(scope.row)">删除</el-button>
            </template>
        </el-table-column>
    </el-table>
    <el-dialog v-model="dialog_opened" :before-close="reset">
        <el-form :model="form" ref="form" label-width="100px">
            <el-form-item label="URL" prop="url" :rules="[{required: true, message: '请输入链接'}]">
                <el-input v-model="form.url" :disabled="loading"></el-input>
            </el-form-item>
            <el-form-item label="name" prop="name" :rules="[{required: true, message: '请输入游戏名'}]">
              <el-input v-model="form.name" :disabled="loading"></el-input>
            </el-form-item>
            <el-form-item label="category" prop="category" :rules="[{required: true, message: '请输入游戏类别'}]">
              <el-select v-model="form.category" :disabled="loading" filterable>
                <el-option v-for="c in category_list" :label="c" :value="c"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="label" prop="label">
              <el-select v-model="form.label" :disabled="loading" filterable clearable>
                <el-option v-for="c in label_list" :label="c" :value="c"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="tags">
              <el-select v-model="form.tags" style="width: 100%" filterable clearable :disabled="loading" multiple allow-create>
                <el-option v-for="i in tag_list" :value="i"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="图片" prop="cover" :rules="[{required: true, message: '请上传封面图'}]">
                <el-upload action="/cms/v1/resource" :show-file-list="false" :onSuccess="handleSuccess"
                           accept="image/*" :data="{prefix: 'game'}" :onProgress="handleProgress">
                    <img v-if="form.cover" :src="`${$root.cdn_url}/${form.cover}`" style="width: 200px">
                    <el-button size="small" v-else :loading="loading">上传</el-button>
                </el-upload>
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button type="primary" :loading="loading" @click="reset" text>取消</el-button>
            <el-button type="primary" :loading="loading" @click="submit">确定</el-button>
        </template>
    </el-dialog>
</template>

<script>
import axios from "ts-axios-new";
import {update, deepcopy} from "../libs/utils";
import {ElMessageBox} from 'element-plus'

export default {
    name: "Game",
    data() {
        return {
            loading: false, data: [], editing: null, dialog_opened: false,
            category_list: ["Adventure", "Casual", "Action", "Strategy", "Shooting", "Racing", "Puzzle", "Match",
              "Sports", "Simulation", "IO"],
            label_list: ["Hot"],
            tag_list: [], suggested_tag_list: ["Casual"],
            form: this.createForm(),
        }
    },
    methods: {
        init() {
            this.loading = true;
            axios.all([
                axios.get('/cms/v1/game').then(res => {
                    this.data = res.data.data.gameList;
                    this.tag_list = []
                    this.tag_list.push(...this.suggested_tag_list)
                    this.data.forEach(g => {
                        if(g.tags) {
                          g.tags.forEach(t => {
                            if (!this.tag_list.includes(t)) {
                              this.tag_list.push(t);
                            }
                          })
                        }
                      }
                    )
                }),
            ]).then(_ => {
                this.loading = false;
            })
        },
        openDialog(item) {
            this.dialog_opened = true;
            this.$nextTick(_ => {
                if (item) {
                    update(this.form, item);
                    this.editing = item;
                }
            })
        },
        reset() {
            if (this.$refs.form) {
              this.$refs.form.resetFields();
            }
            this.dialog_opened = this.loading = false;
            this.editing = null;
            this.form = this.createForm()
        },
        submit() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.loading = true;
                    if (this.editing) {
                        axios.put(`/cms/v1/game/${this.editing.id}`, this.form).then(res => {
                            update(this.editing, res.data.data);
                            this.reset();
                        })
                    } else {
                        axios.post(`/cms/v1/game`, this.form).then(res => {
                            this.data.push(res.data.data);
                            this.reset();
                        });
                    }
                }
            })
        },
        remove(row) {
            ElMessageBox.confirm('确定要删除该项吗？', '提示', {
                cancelButtonText: '取消',
                confirmButtonText: '确定',
                type: 'warning',
            }).then(_ => {
                this.loading = true;
                axios.delete(`/cms/v1/game/${row.id}`).then(_ => {
                    this.data.splice(this.data.indexOf(row), 1);
                    this.loading = false;
                })
            }).catch(_ => {
            });
        },
        handleSuccess(res) {
            this.loading = false;
            this.form.cover = res.data.name
        },
        handleProgress() {
            this.loading = true;
        },
        update(row) {
            this.loading = true;
            axios.put(`/cms/v1/game/${row.id}`, row).then(_ => {
                this.loading = false;
            });
        },
        moveUp(index) {
            const row = this.data.splice(index, 1);
            this.data.splice(index - 1, 0, row[0]);
            this.resort();
        },
        moveDown(index) {
            const row = this.data.splice(index, 1);
            this.data.splice(index + 1, 0, row[0]);
            this.resort();
        },
        resort() {
            const sort = [];
            this.data.forEach(d => {
                sort.push(d.id);
            })
            this.loading = true;
            axios.post(`/cms/v1/sort/game`, {sort}).then(_ => {
                this.loading = false;
            })
        },
        createForm() {
          return {
            cover: '',
            url: "",
            active: false,
            name: "",
            category: "",
            label: "",
            tags: [],
          }
        }
    },
    mounted() {
        this.init();
    }
}
</script>

<style scoped>

</style>