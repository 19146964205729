<template>
    <div style="display: flex;flex-wrap: wrap;">
        <el-select v-model="query.category_id" clearable filterable placeholder="分类" @change="reload">
            <el-option v-for="c in category_list" :label="c.i18n_name.ZH_HANS" :value="c.id"></el-option>
        </el-select>
        <el-cascader v-model="query.major_tag" placeholder="主要标签" clearable filterable :options="major_list"
                     :props="props" @change="reload"></el-cascader>
        <el-cascader v-model="query.minor_tag" placeholder="次要标签" clearable filterable :options="minor_list"
                     :props="props" @change="reload"></el-cascader>
        <el-cascader v-model="query.assistant_tag" placeholder="辅助标签" clearable filterable :options="assistant_list"
                     :props="props" @change="reload"></el-cascader>
        <el-select v-model="query.status" placeholder="审核状态" clearable @change="reload" style="width: 100px">
            <el-option label="审核通过" value="AUDITED"></el-option>
            <el-option label="审核中" value="AUDITING"></el-option>
            <el-option label="审核不通过" value="REJECTED"></el-option>
        </el-select>
        <el-select v-model="query.unauthorized" placeholder="版权状态" clearable @change="reload" style="width: 100px">
            <el-option label="无版权" :value="true"></el-option>
            <el-option label="有版权" :value="false"></el-option>
        </el-select>
        <el-select v-model="query.online" placeholder="上线状态" clearable @change="reload" style="width: 100px">
            <el-option label="已上线" :value="true"></el-option>
            <el-option label="未上线" :value="false"></el-option>
        </el-select>
        <el-select v-model="query.source" placeholder="来源" clearable @change="reload" style="width: 150px">
            <el-option v-for="s in source_list" :value="s"></el-option>
        </el-select>
        <el-select v-model="query.module" placeholder="模块" clearable @change="reload" style="width: 100px">
            <el-option label="图库" value="LIBRARY"></el-option>
            <el-option label="集合" value="COLLECTION"></el-option>
            <el-option label="每日" value="DAILY"></el-option>
            <el-option label="运营活动" value="EVENT"></el-option>
        </el-select>
        <el-input v-model="query.id" placeholder="ID" @change="reload" style="width: 220px"></el-input>
        <date-range-picker v-model="query" end-placeholder="上线时间结束" start-placeholder="上线时间开始" style="width: 200px"
                           @change="reload" clearable :disabled-date="function() {}"></date-range-picker>
        <el-button :disabled="loading" :type="editButton.type" @click="openEdit()">{{editButton.text}}</el-button>
        <el-button :disabled="loading" type="primary" @click="openDialog()">上传</el-button>
    </div>
    <el-table style="margin-top: 20px;" v-loading="loading" :data="data">
        <el-table-column label="序号" width="80">
          <template #default="scope">
            {{ scope.$index + 1 }}
          </template>
        </el-table-column>
        <el-table-column label="ID" prop="id" width="130"></el-table-column>
        <el-table-column label="状态" prop="status" width="100">
            <template #default="scope">
                <el-select :disabled="loading" style="width: 80px" v-model="scope.row.status"
                           @change="update(scope.row)">
                    <el-option label="通过" value="AUDITED"></el-option>
                    <el-option label="审核中" value="AUDITING"></el-option>
                    <el-option label="不通过" value="REJECTED"></el-option>
                </el-select>
            </template>
        </el-table-column>
        <el-table-column label="图片" prop="resource" width="200">
            <template #default="scope">
                <el-image lazy :src="`https://jigsaw-cdn.plutodesk.com/${scope.row.thumbnail}`" style="width: 180px"/>
                <div style="text-align: center;">
                    <a :href="`https://jigsaw-cdn.plutodesk.com/${scope.row.resource}`" target="_blank">高清大图</a>
                </div>
            </template>
        </el-table-column>
        <el-table-column label="价格" prop="cost" width="100">
            <template #default="scope">
                <el-select v-model="scope.row.cost" style="width: 80px" @change="update(scope.row)">
                    <el-option :value="0"></el-option>
                    <el-option :value="20"></el-option>
                </el-select>
            </template>
        </el-table-column>
        <el-table-column label="来源" prop="source" width="100"></el-table-column>
        <el-table-column label="上线时间" prop="online_date" width="140">
            <template #default="scope">
                <el-date-picker style="width: 120px" value-format="YYYY-MM-DD" v-model="scope.row.online_date"
                                @change="update(scope.row)" :clearable="false"></el-date-picker>
            </template>
        </el-table-column>
        <el-table-column label="平台" prop="platform" width="80">
          <template #default="scope">
            <el-tag v-if="scope.row.platform">{{scope.row.platform}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="模块" prop="module" width="60">
            <template #default="scope">
                {{ {LIBRARY: '图库', COLLECTION: '集合', DAILY: '每日', EVENT: '活动'}[scope.row.module] }}
            </template>
        </el-table-column>
        <el-table-column label="分类" prop="categories" width="80">
            <template #default="scope">
                <el-tag v-for="c in scope.row.categories">{{ category_map[c] }}</el-tag>
            </template>
        </el-table-column>
        <el-table-column label="主要标签" prop="major_tags" width="100">
            <template #default="scope">
                <el-tag v-for="t in scope.row.major_tags">{{ major_map[t] }}</el-tag>
            </template>
        </el-table-column>
        <el-table-column label="次要标签" prop="minor_tags" width="100">
            <template #default="scope">
                <el-tag v-for="t in scope.row.minor_tags">{{ minor_map[t] }}</el-tag>
            </template>
        </el-table-column>
        <el-table-column label="辅助标签" prop="assistant_tags" width="100">
            <template #default="scope">
                <el-tag v-for="t in scope.row.assistant_tags">{{ assistant_map[t] }}</el-tag>
            </template>
        </el-table-column>
        <el-table-column label="版权状态" width="200">
          <template #default="scope">
            <el-tag v-if="scope.row.unauthorized && !edit_unauthorized" type="danger">无版权</el-tag>
            <el-checkbox v-if="edit_unauthorized" v-model="scope.row.unauthorized" :disabled="loading" @change="updateUnauthorized(scope.row)">无版权</el-checkbox>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="200">
            <template #default="scope">
                <el-button :disabled="loading" size="small" @click="openDialog(scope.row)">编辑</el-button>
                <el-button :disabled="loading" size="small" type="danger" @click="remove(scope.row)">删除</el-button>
            </template>
        </el-table-column>
    </el-table>
    <div style="display: flex; justify-content: space-between">
        <el-pagination layout="prev, pager, next" :total="total" :page-size="this.query.limit"
                       @current-change="pageChange" :disabled="loading"></el-pagination>
        <el-select v-model="query.limit" style="width: 100px" @change="reload" :disabled="loading">
            <el-option :value="20"></el-option>
            <el-option :value="50"></el-option>
            <el-option :value="100"></el-option>
        </el-select>
    </div>
    <el-dialog v-model="dialog_opened" :before-close="reset">
        <el-form ref="form" label-width="100px" :model="form">
            <el-form-item label="选择图片" prop="resource" :rules="[{required: true, message: '请选择图片'}]">
                <el-upload action="/cms/v1/resource" :show-file-list="false" :onSuccess="handleSuccess"
                           accept="image/*" :data="{prefix: 'images'}" :onProgress="handleProgress"
                           :before-upload="beforeUpload">
                    <img v-if="form.resource" :src="`https://jigsaw-cdn.plutodesk.com/${form.resource}`"
                         style="width: 200px">
                    <el-button size="small" v-else :loading="loading">上传</el-button>
                </el-upload>
            </el-form-item>
            <el-form-item label="平台" prop="platform">
              <el-select v-model="form.platform" filterable placeholder="请选择平台" :disabled="loading" style="width: 140px">
                <el-option v-for="c in [null, 'android', 'ios']" :value="c" :label="c === null ? 'all' : c"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="分类" prop="categories" :rules="[{required: true, message: '请选择分类'}]">
                <el-select v-model="form.categories" multiple filterable :disabled="loading" style="width: 100%">
                    <el-option v-for="c in category_list" :value="c.id" :label="c.i18n_name.ZH_HANS"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="主要标签" prop="major_tags" :rules="[{required: true, message: '请选择主要标签'}]">
                <el-tag v-for="t in form.major_tags" closable @close="removeTag('major_tags', t)">{{
                        major_map[t]
                    }}
                </el-tag>
                <el-popover trigger="click" v-if="form.major_tags.length < 1">
                    <el-cascader size="mini" :options="major_list" filterable :props="props"
                                 @change="addTag($event, 'major_tags')"></el-cascader>
                    <template #reference>
                        <el-button size="mini" type="text">添加
                        </el-button>
                    </template>
                </el-popover>
            </el-form-item>
            <el-form-item label="次要标签" prop="minor_tags" :rules="[{required: true, message: '请选择次要标签'}]">
                <el-tag v-for="t in form.minor_tags" closable @close="removeTag('minor_tags', t)">{{
                        minor_map[t]
                    }}
                </el-tag>
                <el-popover trigger="click">
                    <el-cascader size="mini" :options="minor_list" filterable :props="props"
                                 @change="addTag($event, 'minor_tags')"></el-cascader>
                    <template #reference>
                        <el-button size="mini" type="text">添加
                        </el-button>
                    </template>
                </el-popover>
            </el-form-item>
            <el-form-item label="辅助标签" prop="assistant_tags" :rules="[{required: true, message: '请选择辅助标签'}]">
                <el-tag v-for="t in form.assistant_tags" closable @close="removeTag('assistant_tags', t)">
                    {{ assistant_map[t] }}
                </el-tag>
                <el-popover trigger="click">
                    <el-cascader size="mini" :options="assistant_list" filterable :props="props"
                                 @change="addTag($event, 'assistant_tags')"></el-cascader>
                    <template #reference>
                        <el-button size="mini" type="text">添加
                        </el-button>
                    </template>
                </el-popover>
            </el-form-item>
            <el-form-item label="来源" prop="source">
                <el-select v-model="form.source" filterable allow-create :disabled="loading">
                    <el-option v-for="s in source_list" :value="s"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="模块" prop="module">
                <el-radio-group v-model="form.module" :disabled="loading">
                    <el-radio label="LIBRARY">图库</el-radio>
                    <el-radio label="DAILY">Daily</el-radio>
                    <el-radio label="COLLECTION">集合</el-radio>
                    <el-radio label="EVENT">活动</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="价格" prop="cost">
                <el-select v-model="form.cost" :disabled="loading">
                    <el-option :value="0"></el-option>
                    <el-option :value="20"></el-option>
                </el-select>
            </el-form-item>
<!--            <el-form-item label="活动标签" prop="activity">-->
<!--                <el-radio-group v-model="form.activity" :disabled="loading">-->
<!--                    <el-radio :label="null">无</el-radio>-->
<!--                    <el-radio :label="2">复活节</el-radio>-->
<!--                </el-radio-group>-->
<!--            </el-form-item>-->
            <el-form-item label="版权" prop="unauthorized">
                <el-checkbox v-model="form.unauthorized" :disabled="loading">无版权</el-checkbox>
            </el-form-item>
            <el-form-item label="包含国家" prop="include_country">
                <el-select v-model="form.include_country" filterable multiple :disabled="loading" style="width: 100%">
                    <el-option v-for="c in $root.country_list" :value="c.code"
                               :label="c.en_name + ' (' + c.code + ')'"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="排除国家" prop="exclude_country">
                <el-select v-model="form.exclude_country" filterable multiple :disabled="loading" style="width: 100%">
                    <el-option v-for="c in $root.country_list" :value="c.code"
                               :label="c.en_name + ' (' + c.code + ')'"></el-option>
                </el-select>
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button type="primary" :loading="loading" text @click="reset">取消</el-button>
            <el-button type="primary" :loading="loading" @click="submit">确定</el-button>
        </template>
    </el-dialog>
</template>

<script>
import axios from "ts-axios-new";
import DateRangePicker from "../base/DateRangePicker";
import {update} from "../libs/utils";
import {ElMessage, ElMessageBox} from 'element-plus'

export default {
    name: "Index",
    components: {DateRangePicker},
    data() {
        return {
            loading: false, data: [], total: 0, page: 1, dialog_opened: false, editing: null,
            category_list: [], major_list: [], minor_list: [], assistant_list: [],
            category_map: {}, major_map: {}, minor_map: {}, assistant_map: {}, source_list: [],
            edit_unauthorized : false,
            props: {expandTrigger: 'hover', label: 'name', value: 'id', emitPath: false},
            query: {
                id: null,
                online: null,
                category_id: null,
                status: null,
                source: null,
                module: null,
                major_tag: null,
                minor_tag: null,
                assistant_tag: null,
                limit: 20,
                skip: 0,
                start: null,
                end: null,
                unauthorized: null
            },
            form: {
                resource: '',
                categories: [],
                cost: 0,
                platform: null,
                source: null,
                major_tags: [],
                minor_tags: [],
                assistant_tags: [],
                operation_tags: [],
                module: 'LIBRARY',
                activity: null,
                status: null,
                online_date: null,
                include_country: [],
                exclude_country: [],
                desc: null,
                unauthorized: null,
                include_region: [],
                exclude_region: [],
            }
        }
    },
    computed: {
      editButton() {
        if (this.edit_unauthorized) {
          return {
            type: 'danger',
            text: '取消编辑状态'
          };
        } else {
          return {
            type: 'primary',
            text: '进入编辑状态'
          };
        }
      },
    },
    methods: {
        init() {
            this.loading = true;
            axios.get(`/cms/v1/image`, {params: this.query}).then(res => {
                this.data = res.data.data.imageList;
                this.total = res.data.data.total;
                this.loading = false;
            });
        },
        reload() {
            if (!this.query.id || this.query.id.length === 24) {
                this.skip = 0;
                this.page = 1;
                this.init();
            }
        },
        df(map, list) {
            list.forEach(l => {
                map[l.id] = l.name;
                if (l.children.length) {
                    this.df(map, l.children);
                }
            });
        },
        pageChange(page) {
            this.query.skip = (page - 1) * this.query.limit;
            this.init();
        },
        update(row) {
            this.loading = true;
            axios.put(`/cms/v1/image/${row.id}`, row).then(res => {
                this.loading = false;
            })
        },
        updateUnauthorized(row) {
          axios.put(`/cms/v1/image/${row.id}`, row).then(res => {
          })
        },
        openEdit() {
          this.edit_unauthorized = !this.edit_unauthorized;
        },
        openDialog(item) {
            this.dialog_opened = true;
            this.$nextTick(_ => {
                if (item) {
                    update(this.form, item);
                    this.editing = item;
                }
            })
        },
        reset() {
            this.$refs.form.resetFields();
            this.loading = this.dialog_opened = false;
            this.editing = null;
            this.form.platform = null;
            this.form.categories = [];
            this.form.major_tags = [];
            this.form.minor_tags = [];
            this.form.assistant_tags = [];
            this.form.include_country = [];
            this.form.exclude_country = [];
            this.form.unauthorized = null;
        },
        submit() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.loading = true;
                    if (this.editing) {
                        axios.put(`/cms/v1/image/${this.editing.id}`, this.form).then(res => {
                            if (res.data.data.source && !this.source_list.includes(res.data.data.source)) {
                                this.source_list.push(res.data.data.source);
                            }
                            update(this.editing, res.data.data);
                            this.reset();
                        })
                    } else {
                        axios.post('/cms/v1/image', this.form).then(res => {
                            if (!this.source_list.includes(res.data.data.source)) {
                                this.source_list.push(res.data.data.source);
                            }
                            this.data.unshift(res.data.data);
                            this.reset();
                        })
                    }
                }
            })
        },
        handleSuccess(res) {
            this.loading = false;
            this.form.resource = res.data.name
        },
        handleProgress() {
            this.loading = true;
        },
        beforeUpload(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = e => {
                    const image = new Image();
                    image.src = e.target.result;
                    image.onload = _ => {
                        if (image.height === 2048 && image.width === 2048) {
                            resolve();
                        } else {
                            reject();
                        }
                    }
                }
            }).then(_ => {
                return file;
            }, _ => {
                ElMessage.error('图片尺寸必须是2048 * 2048');
                return Promise.reject()
            })
        },
        removeTag(category, tag) {
            this.form[category].splice(this.form[category].indexOf(tag), 1);
        },
        addTag(value, category) {
            this.form[category].push(value);
        },
        remove(row) {
            ElMessageBox.confirm('确定要删除该项吗？', '提示', {
                cancelButtonText: '取消',
                confirmButtonText: '确定',
                type: 'warning',
            }).then(_ => {
                this.loading = true;
                axios.delete(`/cms/v1/image/${row.id}`).then(res => {
                    this.data.splice(this.data.indexOf(row), 1);
                    this.loading = false;
                })
            })
        }
    },
    mounted() {
        this.loading = true;
        axios.all([
            axios.get(`/cms/v1/category`).then(res => {
                this.category_list = res.data.data.categoryList.filter(c => !['ALL', 'COLLECTION'].includes(c.key));
                this.category_list.forEach(c => {
                    this.category_map[c.id] = c.i18n_name.ZH_HANS;
                });
            }),
            axios.get('/cms/v1/tag/major').then(res => {
                this.major_list = res.data.data.tagList;
                this.df(this.major_map, this.major_list);
            }),
            axios.get('/cms/v1/tag/minor').then(res => {
                this.minor_list = res.data.data.tagList;
                this.df(this.minor_map, this.minor_list);
            }),
            axios.get('/cms/v1/tag/assistant').then(res => {
                this.assistant_list = res.data.data.tagList;
                this.df(this.assistant_map, this.assistant_list);
            }),
            axios.get('/cms/v1/source').then(res => {
                this.source_list = [];
                res.data.data.sourceList.forEach(s => {
                    if (s && !this.source_list.includes(s)) {
                        this.source_list.push(s);
                    }
                });
            }),
        ]).then(_ => {
            this.init();
        })
    },
}
</script>

<style scoped>

.el-select {
  width: 200px;
}

</style>