<template>
    <div>
        <el-button type="primary" :disabled="loading" @click="openDialog()">新建</el-button>
    </div>
    <el-table style="margin-top: 10px" v-loading="loading" :data="data">
        <el-table-column label="ID" width="150" prop="id"></el-table-column>
        <el-table-column label="图片" width="300px" prop="cover">
            <template #default="scope">
                <el-image lazy :src="`${$root.cdn_url}/${scope.row.cover}`"
                          style="width: 250px"></el-image>
            </template>
        </el-table-column>
        <el-table-column label="类型" width="150">
          <template #default="scope">
            {{type_map[scope.row.type]}}
          </template>
        </el-table-column>
        <el-table-column label="目标" width="150" prop="target"></el-table-column>
        <el-table-column label="时间" width="150"></el-table-column>
        <el-table-column label="上线" width="100px" prop="active">
            <template #default="scope">
                <el-switch v-model="scope.row.active" :inactive-value="false" :active-value="true"
                           @change="update(scope.row)"></el-switch>
            </template>
        </el-table-column>
      type_map
        <el-table-column label="操作" width="250">
            <template #default="scope">
                <el-button type="success" circle icon="Top" :loading="loading"
                           :disabled="scope.$index===0" @click="moveUp(scope.$index)"></el-button>
                <el-button type="success" circle icon="Bottom" :loading="loading"
                           :disabled="scope.$index===data.length - 1" @click="moveDown(scope.$index)"></el-button>
                <el-button size="small" :disabled="loading" @click="openDialog(scope.row)">编辑</el-button>
                <el-button size="small" type="danger" :disabled="loading" @click="remove(scope.row)">删除</el-button>
            </template>
        </el-table-column>
    </el-table>
    <el-dialog v-model="dialog_opened" :before-close="reset">
        <el-form :model="form" ref="form" label-width="100px">
            <el-form-item label="游戏" prop="game_id" :rules="[{required: true, message: '请选择游戏'}]">
              <el-select v-model="form.game_id" :disabled="loading" filterable>
                <el-option v-for="c in game_list" :label="c.name" :value="c.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="活动类型" prop="type" :rules="[{required: true, message: '请选择类型'}]">
              <el-select v-model="form.type" :disabled="loading" filterable>
                <el-option v-for="c in [0]" :label="type_map[c]" :value="c"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="活动目标" prop="target" :rules="[{required: true, message: '请选择类型'}]">
              <el-input v-model="form.target" :disabled="loading" type="number" style="width: 200px;"></el-input>
            </el-form-item>

            <el-form-item label="开始时间" prop="start" :rules="[{required: true, message: '请选择类型'}]">
              <el-date-picker value-format="YYYY-MM-DD" :disabled="loading" v-model="form.start" style="width: 200px"></el-date-picker>
            </el-form-item>

            <el-form-item label="结束时间" prop="end" :rules="[{required: true, message: '请选择类型'},
              { validator: (rule, value, callback) => (form.start && value && value <= form.start) ?
                callback('结束时间必须大于开始时间') : callback() }]">
              <el-date-picker value-format="YYYY-MM-DD" :disabled="loading" v-model="form.end" style="width: 200px"></el-date-picker>
            </el-form-item>

            <el-form-item label="活动描述" prop="desc" :rules="[{required: false, message: '请输入活动描述'}]">
              <el-input v-model="form.desc" :disabled="loading"></el-input>
            </el-form-item>

            <el-form-item label="海报" prop="cover" :rules="[{required: true, message: '请上传封面图'}]">
                <el-upload action="/cms/v1/resource" :show-file-list="false" :onSuccess="handleSuccess"
                           accept="image/*" :data="{prefix: 'game'}" :onProgress="handleProgress">
                    <img v-if="form.cover" :src="`${$root.cdn_url}/${form.cover}`" style="width: 200px">
                    <el-button size="small" v-else :loading="loading">上传</el-button>
                </el-upload>
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button type="primary" :loading="loading" @click="reset" text>取消</el-button>
            <el-button type="primary" :loading="loading" @click="submit">确定</el-button>
        </template>
    </el-dialog>
</template>

<script>
import axios from "ts-axios-new";
import {update, deepcopy} from "../libs/utils";
import {ElMessageBox} from 'element-plus'

export default {
    name: "Game",
    data() {
        return {
            loading: false, data: [], editing: null, dialog_opened: false, game_list: [],
            type_map: {0: "时长活动"},
            form: {
                cover: '',
                game_id: "",
                type: '',
                target: null,
                start: null,
                end: null,
                active: false,
                desc: ""
            },
        }
    },
    methods: {
        init() {
            this.loading = true;
            axios.all([
                axios.get('/cms/v1/game-task').then(res => {
                    this.data = res.data.data.taskList;
                }),
                axios.get('/cms/v1/game').then(res => {
                    this.game_list = res.data.data.gameList;
                }),
            ]).then(_ => {
                this.loading = false;
            })
        },
        openDialog(item) {
            this.dialog_opened = true;
            this.$nextTick(_ => {
                if (item) {
                    update(this.form, item);
                    this.editing = item;
                }
            })
        },
        reset() {
            this.$refs.form.resetFields();
            this.dialog_opened = this.loading = false;
            this.editing = null;
        },
        submit() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.loading = true;
                    if (this.editing) {
                        axios.put(`/cms/v1/game-task/${this.editing.id}`, this.form).then(res => {
                            update(this.editing, res.data.data);
                            this.reset();
                        })
                    } else {
                        axios.post(`/cms/v1/game-task`, this.form).then(res => {
                            this.data.push(res.data.data);
                            this.reset();
                        });
                    }
                }
            })
        },
        remove(row) {
            ElMessageBox.confirm('确定要删除该项吗？', '提示', {
                cancelButtonText: '取消',
                confirmButtonText: '确定',
                type: 'warning',
            }).then(_ => {
                this.loading = true;
                axios.delete(`/cms/v1/game-task/${row.id}`).then(_ => {
                    this.data.splice(this.data.indexOf(row), 1);
                    this.loading = false;
                })
            }).catch(_ => {
            });
        },
        handleSuccess(res) {
            this.loading = false;
            this.form.cover = res.data.name
        },
        handleProgress() {
            this.loading = true;
        },
        update(row) {
            this.loading = true;
            axios.put(`/cms/v1/game-task/${row.id}`, row).then(_ => {
                this.loading = false;
            });
        },
        moveUp(index) {
            const row = this.data.splice(index, 1);
            this.data.splice(index - 1, 0, row[0]);
            this.resort();
        },
        moveDown(index) {
            const row = this.data.splice(index, 1);
            this.data.splice(index + 1, 0, row[0]);
            this.resort();
        },
        resort() {
            const sort = [];
            this.data.forEach(d => {
                sort.push(d.id);
            })
            this.loading = true;
            axios.post(`/cms/v1/sort/game-task`, {sort}).then(_ => {
                this.loading = false;
            })
        },
    },
    mounted() {
        this.init();
    }
}
</script>

<style scoped>

</style>